
<style scoped lang="less">
.Student_Move {
  background: #fff;
  padding: 20px;
  box-shadow: @shadow;
  .item {
    display: flex;
    align-items: center;
    margin-top: 20px;
    .el-select {
      width: 220px;
      margin: 0 10px;
    }
  }
}
</style>
<template>
  <div class="Student_Move">
    <div class="stu-module-header">
      <div class="stu-module-title">个人流水</div>
    </div>
    <div style="margin-top: 50px">
      <el-table :data="tableData" border
                size="medium"
                height="630px"
                max-height="630px"
                :header-cell-style="{'text-align':'center'}"
                :cell-style="{'text-align':'center'}"
                style="width: 100%">
        <el-table-column type="index" width="80" label="序号">
        </el-table-column>
        <el-table-column prop="dealAmount" sortable label="交易金额">
        </el-table-column>
        <el-table-column sortable label="交易类型">
          <template slot-scope="scope">
            <div v-if="scope.row.dealType === 0">课程</div>
            <div v-if="scope.row.dealType === 1">教材费</div>
            <div v-if="scope.row.dealType === 2">学费</div>
            <div v-if="scope.row.dealType === 3">解冻资金</div>
            <div v-if="scope.row.dealType === 4">提现发起</div>
            <div v-if="scope.row.dealType === 5">提现退回</div>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" sortable :formatter="dateFormat" label="创建时间">
        </el-table-column>
        <el-table-column sortable label="订单状态">
          <template slot-scope="scope">
            <div v-if="scope.row.orderType === 0">支出</div>
            <div v-if="scope.row.orderType === 1">收入</div>
          </template>
        </el-table-column>
        <el-table-column prop="orderNo" sortable label="订单号">
        </el-table-column>
        <el-table-column prop="remark" sortable label="备注">
        </el-table-column>
      </el-table>
      <!-- 共页数 -->
      <div style="padding: 1rem" class="bottom">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="search.page"
            :page-sizes="[10,15,20]"
            :page-size="search.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      total: 0,
      tableData:[],
      search: {
        page: 1,
        pageSize: 10,
      },
    };
  },
  mounted() {
    this.query();
  },
  methods: {
    query() {
      this.openLoadingView();
      this.$axios_supermallData({
        url: "hnjxjy-core/eduStream/queryAllEduStream",
        method: "get",
        params: {
          page: this.search.page,
          pageSize: this.search.pageSize,
        }
      }).then((res) => {
        if (res.data.code === 2000){
          this.tableData = res.data.data.list;
          this.total = res.data.data.total;
        }
        this.loadingView.close();
      }).catch((err) => {
        this.loadingView.close();
      });
    },
    handleSizeChange(val) {
      this.search.pageSize = val;
      this.query();
    },
    handleCurrentChange(val) {
      this.search.page = val;
      this.query();
    },
    dateFormat (value){
      let date = new Date(value.createTime);
      return date.formatToLocalDateTime("yyyy-MM-dd HH:mm:ss");
    }
  }
};
</script>
